import React, { useEffect, Suspense } from 'react';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import { authenticate } from '../state/slices/user';
import { RootState } from '../state';

const Doing = React.lazy(() => import('./Doing/Doing'));
const Planning = React.lazy(() => import('./Planning/Planning'));
const Login = React.lazy(() => import('./Login'));

const Loading = () => <div>LOADING!</div>;

const LoadingWithRedirect: React.FC = () => {
  const startedAuth = useSelector((state: RootState) => state.user.startedAuth);
  const authenticated = useSelector(
    (state: RootState) => state.user.authenticated
  );
  const loading = useSelector((state: RootState) => state.user.loading);

  if (authenticated) {
    return (
      <Redirect
        to={{
          pathname: '/doing',
        }}
      />
    );
  }

  if (startedAuth && !loading && !authenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    );
  }
  return <Loading />;
};

const PrivateRoute: React.FC<any> = ({ children, ...rest }) => {
  const { authenticated, loading, startedAuth } = useSelector(
    (state: RootState) => state.user
  );
  return (
    <Route
      {...rest}
      render={({ location }) =>
        loading || !startedAuth ? (
          <Loading />
        ) : authenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const App: React.FC = () => {
  const startedAuth = useSelector((state: RootState) => state.user.startedAuth);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!startedAuth) {
      dispatch(authenticate());
    }
  }, [dispatch, startedAuth]);
  return (
    <div className='App'>
      <Suspense fallback={<Loading />}>
        <ErrorBoundary>
          <Switch>
            <Route path='/login' exact component={Login} />
            <PrivateRoute path='/doing/:ancestor?'>
              <Doing />
            </PrivateRoute>
            <PrivateRoute path='/planning/:id?'>
              <Planning />
            </PrivateRoute>
            <Route component={LoadingWithRedirect} />
          </Switch>
        </ErrorBoundary>
      </Suspense>
    </div>
  );
};

export default App;
