import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit';

import user from './slices/user';
import goals from './slices/goals';
import doing from './slices/doing';
import planning from './slices/planning';

const rootReducer = combineReducers({
  user,
  goals,
  doing,
  planning,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
