import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  concat,
  HttpLink,
} from '@apollo/client';
import { API } from './config';
import Cookies from 'js-cookie';

const httpLink = new HttpLink({ uri: API });

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = Cookies.get('user');
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });
  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    mutate: {
      fetchPolicy: 'network-only',
    },
  },
});

export default client;
