import { gql } from '@apollo/client';

export const GoalPartsFragment = gql`
  fragment GoalParts on Goal {
    id
    title
    done
    specific
    measurable
    attainable
    relevant
    createdAt
    updatedAt
    dependsOn {
      id
      title
    }
    parentId
    ancestorId
    parent {
      id
      title
    }
    children {
      id
    }
  }
`;
