import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '..';
import Cookies from 'js-cookie';
import client from '../../utils/apollo';
import { gql } from '@apollo/client';

const VERIFY = gql`
  query VERIFY {
    verify {
      authenticated
      user {
        id
        email
      }
    }
  }
`;

const GET_TOKEN = gql`
  mutation GetToken($email: String!) {
    token(email: $email)
  }
`;

type UserCookie = {
  accessToken: string;
};

const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: null,
    email: null,
    error: null,
    loading: false,
    authenticated: false,
    startedAuth: false,
  },
  reducers: {
    login(state) {
      state.startedAuth = false;
    },
    logout(state) {
      Cookies.remove('user');
      state.authenticated = false;
      state.id = null;
      state.email = null;
      state.error = null;
      state.loading = false;
      state.startedAuth = false;
    },
    authenticatingStart(state) {
      state.loading = true;
      state.startedAuth = true;
    },
    authenticatingError(state, action) {
      state.error = action.payload.error;
      state.authenticated = false;
      state.loading = false;
    },
    authenticatingSuccess(state, action) {
      state.id = action.payload.user.id;
      state.email = action.payload.user.email;
      state.authenticated = action.payload.authenticated;
      state.loading = false;
    },
  },
});

export const {
  login,
  logout,
  authenticatingStart,
  authenticatingError,
  authenticatingSuccess,
} = userSlice.actions;

export default userSlice.reducer;

export const authenticate = (): AppThunk => async dispatch => {
  dispatch(authenticatingStart());
  try {
    const {
      data: {
        verify: { authenticated, user },
      },
    } = await client.query({ query: VERIFY });
    if (authenticated) {
      dispatch(
        authenticatingSuccess({
          authenticated,
          user: user,
        })
      );
    } else {
      dispatch(authenticatingError('Authentication Failed'));
    }
  } catch (error) {
    // remove cookie if it existed
    console.log(error);
    dispatch(authenticatingError({ error: error.toString() }));
  }
};

export const createJWT = (email: string): AppThunk => async () => {
  try {
    const result = await client.mutate({
      mutation: GET_TOKEN,
      variables: { email },
      fetchPolicy: 'no-cache',
    });

    if (result.data.token) {
      Cookies.set('user', result.data.token);
      window.location.reload();
    }
  } catch (err) {
    console.log(err);
  }
};
