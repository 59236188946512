import { createSlice, ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { TGoal, TSmart } from '../../utils/types';
import { AppThunk } from '..';
import client from '../../utils/apollo';
import { gql } from '@apollo/client';
import { GoalPartsFragment } from '../../utils/fragments';
import {
  updateGoals,
  deleteGoal as deleteNormalizedGoal,
  updateGoal,
} from './goals';

export const GET_GOAL = gql`
  query GET_GOAL($id: Int!) {
    goal(id: $id) {
      ...GoalParts
    }
  }
  ${GoalPartsFragment}
`;

const GET_SUBGOALS = gql`
  query GET_SUBGOALS($parentId: Int!) {
    subgoals(parentId: $parentId) {
      ...GoalParts
    }
  }
  ${GoalPartsFragment}
`;

export const CREATE_GOAL = gql`
  mutation CREATE_GOAL($params: CreateGoalInput!) {
    createGoal(params: $params) {
      ...GoalParts
    }
  }
  ${GoalPartsFragment}
`;

const DELETE_GOAL = gql`
  mutation DELETE_GOAL($id: Int!) {
    deleteGoal(id: $id)
  }
`;

const UPDATE_GOAL_TITLE = gql`
  mutation UPDATE_GOAL_TITLE($id: Int!, $title: String!) {
    updateGoal(id: $id, goal: { title: $title }) {
      ...GoalParts
    }
  }
  ${GoalPartsFragment}
`;

const UPDATE_GOAL_DETAILS = gql`
  mutation UPDATE_GOAL_TITLE($id: Int!, $goal: UpdateGoalInput!) {
    updateGoal(id: $id, goal: $goal) {
      ...GoalParts
    }
  }
  ${GoalPartsFragment}
`;

const SEARCH_GOALS = gql`
  query SEARCH_GOALS($self: Int!, $searchString: String!) {
    search(self: $self, searchString: $searchString) {
      ...GoalParts
    }
  }
  ${GoalPartsFragment}
`;

const ADD_DEPENDENCY = gql`
  mutation ADD_DEPENDENCY($self: Int!, $dependency: Int!) {
    addDependency(self: $self, dependency: $dependency)
  }
`;

const initialState = {
  isLoading: false,
  goal: null as null | number,
  ancestor: null as null | number,
  subgoals: [] as number[],
  error: '',
  addSubgoalIsLoading: false,
  addSubgoalError: '',
  addSubgoalTitle: '',
  deleteLoading: false,
  deleteError: '',
  deletedMainGoal: false,
  editingGoalTitle: false,
  tempGoalTitle: '',
  updateGoalTitleLoading: false,
  updateGoalTitleError: '',
  tempGoalDetails: {} as TSmart,
  updateGoalDetailsLoading: false,
  updateGoalDetailsError: '',
  breadcrumbs: [] as number[],
  searchDependencies: [] as number[],
  searchDependenciesLoading: false,
  searchDependenciesError: '',
  addDependencyLoading: false,
  addDependencyError: '',
};

function keyOfInitialState(key: string): key is keyof typeof initialState {
  return Object.prototype.hasOwnProperty.call(initialState, key);
}

const planningSlice = createSlice({
  name: 'planning',
  initialState,
  reducers: {
    fetchGoalStart(state) {
      state.isLoading = true;
      state.addSubgoalTitle = '';
    },
    fetchGoalError(state, action) {
      state.isLoading = false;
      state.goal = null;
      state.ancestor = null;
      state.error = action.payload.error.toString();
    },
    fetchGoalSuccess(state, action) {
      state.isLoading = false;
      state.goal = action.payload.goal.id;
      state.ancestor = action.payload.ancestor.id;
      state.subgoals = action.payload.subgoals.map(({ id }: TGoal) => id);
      state.tempGoalDetails = {
        specific: action.payload.goal.specific || '',
        measurable: action.payload.goal.measurable || '',
        attainable: action.payload.goal.attainable || '',
        relevant: action.payload.goal.relevant || '',
      };
      state.breadcrumbs = action.payload.breadcrumbs.map(({ id }: TGoal) => id);
    },
    addSubgoalStart(state) {
      state.addSubgoalIsLoading = true;
    },
    addSubgoalError(state, action) {
      state.addSubgoalIsLoading = false;
      state.addSubgoalError = action.payload.error;
    },
    addSubgoalSuccess(state, action) {
      state.addSubgoalIsLoading = false;
      state.subgoals = action.payload.subgoals.map(({ id }: TGoal) => id);
      state.addSubgoalTitle = '';
    },
    updateAddSubgoalTitle(state, action) {
      state.addSubgoalTitle = action.payload.title;
    },
    deleteStart(state) {
      state.deleteLoading = true;
    },
    deleteError(state, action) {
      state.deleteLoading = false;
      state.deleteError = action.payload.error;
    },
    deleteSuccess(state, action) {
      state.deleteLoading = false;
      if (state.goal && action.payload.id === state.goal) {
        state.deletedMainGoal = true;
      } else {
        // Remove from subgoals
        // TODO: Does it make sense that we're handling logic from different places in UX with one reducer
        state.subgoals = state.subgoals.filter(id => id !== action.payload.id);
      }
    },
    resetPlanningState(state) {
      for (const prop in initialState) {
        if (Object.prototype.hasOwnProperty.call(initialState, prop)) {
          if (keyOfInitialState(prop)) {
            state[prop] = initialState[prop] as never;
          }
        }
      }
    },
    startEditGoalTitle(state) {
      state.editingGoalTitle = true;
    },
    editGoalTitle(state, action) {
      state.tempGoalTitle = action.payload.title;
    },
    stopEditGoalTitle(state) {
      state.editingGoalTitle = false;
    },
    updateGoalTitleStart(state) {
      state.updateGoalTitleLoading = true;
    },
    updateGoalTitleError(state, action) {
      state.updateGoalTitleLoading = false;
      state.updateGoalTitleError = action.payload.error;
    },
    updateGoalTitleSuccess(state, action) {
      state.updateGoalTitleLoading = false;
      state.editingGoalTitle = false;
      state.tempGoalTitle = action.payload.title;
    },
    updateTempGoalDetails(state, action) {
      state.tempGoalDetails[action.payload.smartKey as keyof TSmart] =
        action.payload.value;
    },
    updateGoalDetailsStart(state) {
      state.updateGoalDetailsLoading = true;
    },
    updateGoalDetailsError(state, action) {
      state.updateGoalDetailsLoading = false;
      state.updateGoalDetailsError = action.payload.error;
    },
    updateGoalDetailsSuccess(state) {
      state.updateGoalDetailsLoading = false;
    },
    searchDependenciesStart(state) {
      state.searchDependenciesLoading = true;
    },
    searchDependenciesError(state, action) {
      state.searchDependenciesLoading = false;
      state.searchDependenciesError = action.payload.error;
    },
    searchDependenciesSuccess(state, action) {
      state.searchDependenciesLoading = false;
      state.searchDependencies = action.payload.goals.map(
        ({ id }: TGoal) => id
      );
    },
    addDependencyStart(state) {
      state.addDependencyLoading = true;
    },
    addDependencyError(state, action) {
      state.addDependencyLoading = false;
      state.addDependencyError = action.payload.error;
    },
    addDependencySuccess(state) {
      state.addDependencyLoading = false;
    },
  },
});

export const {
  fetchGoalStart,
  fetchGoalError,
  fetchGoalSuccess,
  addSubgoalStart,
  addSubgoalError,
  addSubgoalSuccess,
  updateAddSubgoalTitle,
  deleteStart,
  deleteError,
  deleteSuccess,
  resetPlanningState,
  startEditGoalTitle,
  stopEditGoalTitle,
  editGoalTitle,
  updateGoalTitleStart,
  updateGoalTitleError,
  updateGoalTitleSuccess,
  updateTempGoalDetails,
  updateGoalDetailsStart,
  updateGoalDetailsError,
  updateGoalDetailsSuccess,
  searchDependenciesStart,
  searchDependenciesError,
  searchDependenciesSuccess,
  addDependencyStart,
  addDependencyError,
  addDependencySuccess,
} = planningSlice.actions;

export default planningSlice.reducer;

async function fetchGoalAndAncestor(id: number) {
  const result = await client.query({ query: GET_GOAL, variables: { id } });
  let ancestorResult = result;
  if (result.data.goal.ancestorId) {
    ancestorResult = await client.query({
      query: GET_GOAL,
      variables: { id: result.data.goal.ancestorId },
    });
  }
  return [result, ancestorResult];
}

async function fetchBreadcrumbs(goal: TGoal) {
  // Returns the array of parent goals that aren't the main goal and aren't the ancestor
  const breadcrumbs = [];
  const { parentId, ancestorId } = goal;
  let currentParentId = parentId;
  let currentGoal;
  while (currentParentId !== ancestorId) {
    const result = await client.query({
      query: GET_GOAL,
      variables: { id: currentParentId },
    });
    currentGoal = result.data.goal;
    breadcrumbs.push(currentGoal);
    currentParentId = currentGoal.parentId;
  }
  return breadcrumbs.reverse();
}

export const fetchGoal = (
  id: number,
  callbackAction?: ActionCreatorWithoutPayload<string>
): AppThunk => async dispatch => {
  dispatch(fetchGoalStart());
  try {
    const [[result, ancestorResult], subgoalsResult] = await Promise.all([
      fetchGoalAndAncestor(id),
      client.query({
        query: GET_SUBGOALS,
        variables: { parentId: id },
      }),
    ]);

    const breadcrumbs = await fetchBreadcrumbs(result.data.goal);

    dispatch(
      updateGoals({
        goals: [
          result.data.goal,
          ancestorResult.data.goal,
          ...subgoalsResult.data.subgoals,
          ...breadcrumbs,
        ],
      })
    );

    dispatch(
      fetchGoalSuccess({
        goal: result.data.goal,
        ancestor: ancestorResult.data.goal,
        subgoals: subgoalsResult.data.subgoals,
        breadcrumbs,
      })
    );

    if (callbackAction) {
      dispatch(callbackAction());
    }
  } catch (err) {
    dispatch(fetchGoalError({ error: err.toString() }));
  }
};

export const addSubgoal = (
  title: string,
  parentId: number | null,
  ancestorId: number | null
): AppThunk => async dispatch => {
  dispatch(addSubgoalStart);
  try {
    await client.mutate({
      mutation: CREATE_GOAL,
      variables: { params: { title, parentId, ancestorId } },
      fetchPolicy: 'no-cache',
    });
    const subgoalsResult = await client.query({
      query: GET_SUBGOALS,
      variables: { parentId },
    });

    dispatch(
      updateGoals({
        goals: subgoalsResult.data.subgoals,
      })
    );

    dispatch(addSubgoalSuccess({ subgoals: subgoalsResult.data.subgoals }));
  } catch (err) {
    dispatch(addSubgoalError({ error: err.toString() }));
  }
};

export const deleteGoal = (id: number): AppThunk => async dispatch => {
  dispatch(deleteStart);
  try {
    await client.mutate({
      mutation: DELETE_GOAL,
      variables: { id },
      fetchPolicy: 'no-cache',
    });
    dispatch(deleteNormalizedGoal({ id }));
    dispatch(deleteSuccess({ id }));
  } catch (err) {
    dispatch(deleteError({ error: err.toString() }));
  }
};

export const updateGoalTitle = (
  id: number,
  title: string
): AppThunk => async dispatch => {
  dispatch(updateGoalTitleStart());
  try {
    const result = await client.mutate({
      mutation: UPDATE_GOAL_TITLE,
      variables: { id, title },
      fetchPolicy: 'no-cache',
    });
    dispatch(updateGoal({ goal: result.data.updateGoal }));
    dispatch(updateGoalTitleSuccess({ title: result.data.updateGoal.title }));
  } catch (err) {
    dispatch(updateGoalTitleError(err.toString()));
  }
};

export const updateGoalDetails = (
  id: number,
  smartKey: string,
  value: string
): AppThunk => async dispatch => {
  dispatch(updateGoalDetailsStart());
  try {
    const result = await client.mutate({
      mutation: UPDATE_GOAL_DETAILS,
      variables: { id, goal: { [smartKey]: value } },
      fetchPolicy: 'no-cache',
    });
    dispatch(updateGoal({ goal: result.data.updateGoal }));
    dispatch(updateGoalDetailsSuccess());
  } catch (err) {
    dispatch(updateGoalDetailsError(err.toString()));
  }
};

export const searchDependencies = (
  self: number,
  searchString: string
): AppThunk => async dispatch => {
  dispatch(searchDependenciesStart());
  try {
    const result = await client.query({
      query: SEARCH_GOALS,
      variables: { self, searchString },
    });
    dispatch(updateGoals({ goals: result.data.search }));
    dispatch(searchDependenciesSuccess({ goals: result.data.search }));
  } catch (err) {
    dispatch(searchDependenciesError({ error: err.toString() }));
  }
};

export const addDependency = (
  self: number,
  dependency: number
): AppThunk => async dispatch => {
  dispatch(addDependencyStart());
  try {
    const result = await client.mutate({
      mutation: ADD_DEPENDENCY,
      variables: { self, dependency },
      fetchPolicy: 'no-cache',
    });
    if (!result.data.addDependency) {
      throw new Error('Error adding dependency');
    }

    dispatch(fetchGoal(self, addDependencySuccess));
  } catch (err) {
    dispatch(addDependencyError({ error: err.toString() }));
  }
};
