import { createSlice, ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { AppThunk } from '..';
import client from '../../utils/apollo';
import { gql } from '@apollo/client';
import { GoalPartsFragment } from '../../utils/fragments';
import { CREATE_GOAL } from './planning';
import { updateGoals, updateGoal } from './goals';
import { TGoal } from '../../utils/types';

// Define Types for Frontend Views
export type TDoingList = {
  id: number;
  subgoals: number[];
};

const GET_LEAVES = gql`
  query GET_LEAVES($ancestorId: Int, $done: Boolean, $dependent: Boolean) {
    leaves(ancestorId: $ancestorId, done: $done, dependent: $dependent) {
      ...GoalParts
    }
  }
  ${GoalPartsFragment}
`;

const UPDATE_GOAL = gql`
  mutation UPDATE_GOAL($id: Int!, $goal: UpdateGoalInput!) {
    updateGoal(id: $id, goal: $goal) {
      ...GoalParts
    }
  }
  ${GoalPartsFragment}
`;

const GET_ANCESTORS = gql`
  query GET_ANCESTORS {
    ancestors {
      ...GoalParts
    }
  }
  ${GoalPartsFragment}
`;

const doingSlice = createSlice({
  name: 'doing',
  initialState: {
    ancestors: [] as number[],
    leaves: [] as number[],
    loading: false,
    error: '',
    updateLoading: false,
    updateError: '',
    activeTodo: null as null | number,
    addNewListLoading: false,
    addNewListError: '',
    addNewListTitle: '',
  },
  reducers: {
    fetchTodosStart(state) {
      state.loading = true;
    },
    fetchTodosError(state, action) {
      state.loading = false;
      state.error = action.payload.error;
    },
    fetchTodosSuccess(state, action) {
      state.loading = false;
      state.ancestors = action.payload.ancestors.map(({ id }: TGoal) => id);
      state.leaves = action.payload.leaves.map(({ id }: TGoal) => id);
    },
    updateTodoStart(state) {
      state.updateLoading = true;
    },
    updateTodoError(state, action) {
      state.updateLoading = false;
      state.updateError = action.payload.error;
    },
    updateTodoSuccess(state) {
      state.updateLoading = false;
      state.activeTodo = null;
    },
    setActiveTodo(state, action) {
      state.activeTodo = action.payload.todo;
    },
    addNewListStart(state) {
      state.addNewListLoading = true;
    },
    addNewListError(state, action) {
      state.addNewListLoading = false;
      state.addNewListError = action.payload.error;
    },
    addNewListSuccess(state) {
      state.addNewListLoading = false;
      state.addNewListTitle = '';
    },
    updateAddNewListTitle(state, action) {
      state.addNewListTitle = action.payload.title;
    },
  },
});

export const {
  fetchTodosStart,
  fetchTodosError,
  fetchTodosSuccess,
  updateTodoStart,
  updateTodoError,
  updateTodoSuccess,
  setActiveTodo,
  addNewListStart,
  addNewListError,
  addNewListSuccess,
  updateAddNewListTitle,
} = doingSlice.actions;

export default doingSlice.reducer;

export const fetchTodos = (
  callbackAction?: ActionCreatorWithoutPayload<string>
): AppThunk => async dispatch => {
  dispatch(fetchTodosStart());
  try {
    const {
      data: { ancestors },
    } = await client.query({ query: GET_ANCESTORS });
    const {
      data: { leaves },
    } = await client.query({ query: GET_LEAVES });
    dispatch(
      updateGoals({
        goals: [...ancestors, ...leaves],
      })
    );
    dispatch(
      fetchTodosSuccess({
        ancestors,
        leaves,
      })
    );
    if (callbackAction) {
      dispatch(callbackAction());
    }
  } catch (err) {
    dispatch(fetchTodosError({ error: err.toString() }));
  }
};

export const markTodoDone = (id: number): AppThunk => async dispatch => {
  dispatch(updateTodoStart());
  try {
    const result = await client.mutate({
      mutation: UPDATE_GOAL,
      variables: { id, goal: { done: true } },
      fetchPolicy: 'no-cache',
    });
    dispatch(updateGoal({ goal: result.data.updateGoal }));
    dispatch(fetchTodos(updateTodoSuccess)); // Refetch Todos
  } catch (err) {
    dispatch(updateTodoError({ error: err.toString() }));
  }
};

export const addNewPlanningList = (
  title: string
): AppThunk => async dispatch => {
  dispatch(addNewListStart());
  try {
    const result = await client.mutate({
      mutation: CREATE_GOAL,
      variables: { params: { title, parentId: null, ancestorId: null } },
      fetchPolicy: 'no-cache',
    });
    dispatch(updateGoal({ goal: result.data.createGoal }));
    dispatch(addNewListSuccess());
    dispatch(fetchTodos());
  } catch (err) {
    dispatch(addNewListError({ error: err.toString() }));
  }
};
