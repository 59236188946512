import { createSlice } from '@reduxjs/toolkit';
import { TGoal } from '../../utils/types';

const initialState = {} as { [k: string]: TGoal };

function updateGoalInState(state: typeof initialState, goal: TGoal) {
  const goalInState = state[goal.id];
  if (goalInState) {
    state[goal.id] = {
      ...goalInState,
      ...goal,
    };
  } else {
    state[goal.id] = goal;
  }
  return state;
}

const goalsSlice = createSlice({
  name: 'goals',
  initialState,
  reducers: {
    updateGoal(state, action) {
      state = updateGoalInState(state, action.payload.goal);
    },
    updateGoals(state, action) {
      for (const goal of action.payload.goals) {
        state = updateGoalInState(state, goal);
      }
    },
    deleteGoal(state, action) {
      delete state[action.payload.id];
    },
  },
});

export const { updateGoal, updateGoals, deleteGoal } = goalsSlice.actions;

export default goalsSlice.reducer;
